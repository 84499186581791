

.App {
  font-family: sans-serif;
  text-align: center;
  
}

.bg {
  
  position:static;
  height: 100vh;
  background-size: cover;
  background-position: center;
 
  background-image:url('./assets/13.jpg');
  display: flex;
align-items: center;
}

.products{
width: 100%;
height: 55vh;
display: flex;
align-items: center;
justify-content: center;
background-image:url('./assets/1.jpg');
color: white;
letter-spacing: 5px;
background-size:cover;
background-position:center;
transition: 1000ms;
}

.products.acp{
  background-image: url('./assets/ACP.jpg');
}

.products.lam{
  background-image: url('./assets/Laminate.jpg');
}

.products.ply{
  background-image: url('./assets/ply.jpg');
}

.products:hover{
  letter-spacing: 0px;
  transition: 1000ms;
  transform: scale(.8);
  color: white;
}


.nav-menu{
  background-color: black;
  width: 100%;
  height: 5vh;
  display: flex;
  align-items: center;
  position: fixed;
  top:0;
  transition:1000ms;
  padding-top: 60px;
  flex-direction: column;
}
.nav-menu.active{
  left: 0;
  transition: 1000ms;
  width: 250px;
}


.menu-item{
  color: white;
  text-align: center;
  padding-top: 30px;
  border-bottom: 1px solid rgba(255, 255,255, .3) ;
  height: 40px;
  width: 200px;
  transition:1000ms;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 11px;
  font-weight: bolder;
  letter-spacing:2px;
  
  
}
.menu-item:hover{
  color:paleturquoise;
  font-size: 12px;
  transition: 200ms;
}

.menu-item.active{
  width: 40px;
  transition: 1000ms;
  height: 20px;

}



