.back {
  
    position:static;
    height: 100vh;
    background-size: cover;
    background-position: center;
   justify-content: center;
    background-image:url('../assets/12.jpg');
    display: flex;
  align-items: center;

 
  }

  .feature{
  
    background-color: rgba(0, 0,0, 0.6);
    padding: 20px;
    width: 300px;
    transition: 500ms ease bottom;
    margin:25px;
    font-size: medium;
    
  
  }

  .feature:hover{
    background-color: rgba(0, 0,0);
    transition: 500ms ease bottom;
  }