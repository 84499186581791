.contact
{
    width: 40%;
    height: 60vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-image:url('../assets/3.jpg');
    color: white;
    letter-spacing: 5px;
    background-size:cover;
    background-position:center;
    transition: 1000ms;
}

.contact:hover{
    letter-spacing: 0px;
    transition: 1000ms;
    transform: scale(.8);
    
  }


  .input{
    width: 40vw;
    padding: 12px 20px;
    margin: 10px 0;
    display: inline-block;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;

  }

.input.mobile{
  width: 80vw;
}

  a:link {
    color:white;
  }
  