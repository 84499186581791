.header {
    zoom: 75%;
    width: 100%;
    z-index: 12;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-evenly;
    height: 20vh;
  }
  
 


  
  .navLink {
   
    list-style: none;
    text-decoration:none;
 
    transition: 1s;
  }
  

  

  
  .navLink:hover {

  
    transition: 1s;
    -webkit-transition: .5s;
  
    transform: scale(1.2);

  }
  

  
  .tooltiptext {
    opacity: 0;
    width: 120px;
    background-color: #303030;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    position: absolute;
    z-index: 1;
    top: 140%;
    right: -125%;
    margin-left: -60px;
  }
  
  .tooltiptext:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    border-width: 5px;
    border-style: solid;
    border-color: transparent transparent #303030 transparent;
  }
  
  .navSocial li:hover {
    background-color: white;
    transition: 0.5s;
    -webkit-transition: 0.5s;
  }
  
  .navSocial li:hover a {
    color: #191919;
  }
  
  .navSocial li:hover .tooltiptext {
    opacity: 0.8;
    transition: opacity 1s;
    -webkit-transition: opacity 1s;
  }
  
  .home {
    border: 2px solid white;
    border-radius: 25px;
  }

  .navbar-item:hover{
    background-color:#f5f7fd;
}