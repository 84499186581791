.footer{
display: flex;
flex-direction: row;
width: 100%;
background-color:#1b1b1b;
text-align: left;
justify-content: space-evenly;

}

.footer.mobile{
    flex-direction: column;
}

.link{
    color: white;
    padding-bottom: 3%;
    font-size: medium;
    font-family:'PT Sans';
    transition: 100ms;
}

.link:hover{
transition: 100ms;
  font-size: 20px;
 

}
a:hover{
    text-decoration: none;
}

.sitmo{
    height: 50px;
    width: 100%;
    background-image: url(./assets/99.jpg);
    background-position: center;
    background-size:auto;
    color: white;
    padding-right: 15px;
    display: flex;
    justify-content:flex-end;
    align-items:flex-end;
}