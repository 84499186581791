.about {
  
    position:static;
    height: 60vh;
    background-size:cover;
    background-position:center;
    background-image:url('../assets/11.jpg');
    display: flex;
  align-items: center;
  justify-content: center;
  

  }
  